import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useStaticQuery, graphql, Link } from 'gatsby';
import * as styles from './UnitFilter.module.scss';
import Calendar from 'react-calendar';
import Plus from '@images/arbol/UnitFilter/plus.svg';
import Minus from '@images/arbol/UnitFilter/minus.svg';
import LeftArrow from '@images/arbol/UnitFilter/left-arrow.svg';
import RightArrow from '@images/arbol/UnitFilter/right-arrow.svg';
import 'react-calendar/dist/Calendar.css';

const priceFilters = ['Any', 2000, 3000, 3500, 4000];
const typeFilters = [
  'View All',
  '0 Bed, 1 Bath',
  '1 Bed, 1 Bath',
  '2 Bed, 1 Bath',
  '2 Bed, 2 Baths',
];

function toCurrency(value) {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

function parseDateStamp(unit) {
  if (unit.available_now) {
    return new Date();
  }

  if (!unit.available_at) {
    return null;
  }

  const date = new Date();
  const dateParts = unit.available_at.split('-').map(p => parseInt(p));

  date.setFullYear(dateParts[0], dateParts[1] - 1, dateParts[2]);
  date.setHours(0, 0, 0, 0);

  return date;
}

function formatDate(unit) {
  if (unit.available_now) {
    return 'NOW';
  }

  const date = parseDateStamp(unit);

  if (!date) {
    return null;
  }

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}.${day}.${date.getFullYear() % 100}`;
}

function renderUnitTile(unit, isTiny, sm = false) {
 
  if (!unit) {
    return <div className={classNames(styles.unitTile, styles.blankTile, { [styles.sm]: sm })} />;
  }

  const image_url =
    unit.grid.url !== null ? unit.grid.url : 'https://via.placeholder.com/662';
  const dateAvailable = formatDate(unit);
  const availabilityText = unit.available_now
    ? 'AVAILABLE NOW'
    : `AVAILABLE ${dateAvailable}`;

  if (isTiny) {
    return (
      <Link
        className={styles.tinyTile}
        to={`/property/envy/${unit.apt_number}`}
      >
        <div
          className={styles.imageContainer}
          style={{ backgroundImage: `url(${image_url})` }}
        >
          {dateAvailable && (
            <div
              className={classNames(styles.availability, {
                [styles.now]: unit.available_now,
                [styles.later]: !unit.available_now,
              })}
            >
              {availabilityText}
            </div>
          )}
        </div>
        <div className={styles.details}>
          <div className={styles.column}>
            <p className={styles.type}>{unit.apt_type}</p>
            <p className={styles.aptNumber}>APT. {unit.apt_number}</p>
          </div>
          <div className={styles.price}>
            {toCurrency(unit.price)}
            <span>/MO*</span>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <Link
      className={classNames(styles.unitTile, { [styles.sm]: sm })}
      style={{ backgroundImage: `url(${image_url})` }}
      to={`/property/envy/${unit.apt_number}`}
    >
      {unit && (
        <>
          {dateAvailable && (
            <div
              className={classNames(styles.availability, {
                [styles.now]: unit.available_now,
                [styles.later]: !unit.available_now,
              })}
            >
              {availabilityText}
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.column}>
              <span className={styles.type}>{unit.apt_type}</span>
              <span className={styles.aptNumber}>APT. {unit.apt_number}</span>
            </div>
            <div className={styles.price}>
              {toCurrency(unit.price)}
              <span>/MO*</span>
            </div>
          </div>
        </>
      )}
    </Link>
  );
}

function renderUnits(
  page,
  pageCount,
  pageData,
  handleNext,
  handleBack,
  isTiny
) {
  const dispatch = useDispatch();

  if (pageData.length === 0) {
    return (
      <div className={classNames(styles.unitsWrapper, styles.empty)}>
        <p>We do not have any apartments that fit the selected filters.</p>
        <p>
          Please adjust the filters to view more apartments or request a tour to
          see similar units and be put on a waitlist for when an apartment is
          available.
        </p>
        <Link
          to="#contact"
          onClick={() => dispatch({ type: 'SET_CONTACT_TOUR' })}
        >
          REQUEST A TOUR
        </Link>
      </div>
    );
  }

  return (
    <div className={styles.unitsWrapper}>
      {page + 1 < pageCount && !isTiny && (
        <div className={styles.nextButton} onClick={handleNext}>
          <div className={styles.content}>
            <div className={styles.position}>
              <span>{page + 1}</span>
              <span>{pageCount}</span>
            </div>
            <div className={styles.arrow} />
          </div>
        </div>
      )}
      {page !== 0 && !isTiny && (
        <div className={styles.backButton} onClick={handleBack}>
          <div className={styles.content}>
            <div className={styles.position}>
              <span>{page + 1}</span>
              <span>{pageCount}</span>
            </div>
            <div className={styles.arrow} />
          </div>
        </div>
      )}

      {isTiny ? (
        <div className={styles.unitColumn} style={{ width: '100%' }}>
          {renderUnitTile(pageData[0], isTiny)}
          {renderUnitTile(pageData[1], isTiny)}
          {renderUnitTile(pageData[2], isTiny)}
        </div>
      ) : (
        <>
          <div className={styles.unitColumn}>
            {renderUnitTile(pageData[0], isTiny)}
            <div className={styles.smallUnitRow}>
              {renderUnitTile(pageData[1], isTiny, true)}
              {renderUnitTile(pageData[2], isTiny, true)}
            </div>
          </div>
          <div className={styles.unitColumn}>
            <div className={styles.smallUnitRow}>
              {renderUnitTile(pageData[3], isTiny, true)}
              {renderUnitTile(pageData[4], isTiny, true)}
            </div>
            {renderUnitTile(pageData[5])}
          </div>
        </>
      )}
    </div>
  );
}

function UnitFilter() {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [isTiny, setIsTiny] = useState(false);
  const pageSize = isTiny ? 3 : 6;

  const data = useStaticQuery(graphql`
    query {
      units: allPrismicEnvyUnit(
        sort: { order: ASC, fields: [data___position, last_publication_date] }
      ) {
        nodes {
          data {
            position
            apt_number
            apt_type
            available_at
            available_now
            price
            grid {
              url
            }
          }
        }
      }
    }
  `);
  let filteredData = data.units.nodes.filter(
    node =>
      node.data.price <= (parseInt(filter?.price) || 100000) &&
      (filter.aptType && filter.aptType !== 'View All'
        ? node.data.apt_type === filter.aptType
        : true) &&
      (filter.date
        ? filter.date.getTime() >= parseDateStamp(node.data).getTime()
        : true)
  );

  filteredData = filteredData.map(node => node.data);

  const dataLength = filteredData.length;
  const pageCount = Math.ceil(dataLength / pageSize);
  const pageData = filteredData.slice(page * pageSize, (page + 1) * pageSize);
  const handleNext = () => setPage(page + 1);
  const handleBack = () => setPage(page - 1);
  const handleDateClick = () => setShowCalendar(!showCalendar);
  const handleDateChange = date => {
    setFilter({
      ...filter,
      date,
    });

    setShowCalendar(false);
  };
  const handleAptClick = () =>
    setFilter({
      ...filter,
      mobileAptType: !filter.mobileAptType,
    });
  const handleCustomClick = () =>
    setFilter({
      ...filter,
      mobilePrice: !filter.mobilePrice,
    });
  const handleOptionClick = e => {
    setFilter({
      ...filter,
      [e.target.dataset.name]: e.target.dataset.value,
      mobilePrice: false,
      mobileAptType: false,
    });
  };

  useEffect(() => {
    const handler = () => setIsTiny(window.innerWidth <= 694);
    window.addEventListener('resize', handler);
    setIsTiny(window.innerWidth <= 694);

    return () => window.removeEventListener('resize', handler);
  }, []);

  useEffect(() => setPage(0), [filter]);

  return (
    <div className={styles.container} id="units">
      <div className={styles.wrapper}>
        <div className={styles.filtersContainer}>
          <div
            className={classNames(styles.filterToggle, {
              [styles.collapsed]: !showFilters,
            })}
            onClick={() => setShowFilters(!showFilters)}
          >
            <span>FILTERS</span>
            {showFilters ? <Minus /> : <Plus />}
          </div>
          <div
            className={classNames(styles.filter, {
              [styles.hidden]: !showFilters,
            })}
          >
            <label>MAX PRICE</label>
            <div onClick={handleCustomClick} className={styles.customSelect}>
              {filter.price || 'Any'}
            </div>
            {filter.mobilePrice && (
              <div className={styles.customDropdown}>
                {priceFilters.map(value => (
                  <div
                    key={value}
                    className={classNames(styles.customOption, {
                      [styles.selected]: parseInt(filter.price) === value,
                    })}
                    data-name="price"
                    data-value={value}
                    onClick={handleOptionClick}
                    readOnly
                  >
                    {toCurrency(value)}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={classNames(styles.filter, {
              [styles.hidden]: !showFilters,
            })}
          >
            <label>APARTMENT SIZE</label>
            <div onClick={handleAptClick} className={styles.customSelect}>
              {filter.aptType || 'Any'}
            </div>
            {filter.mobileAptType && (
              <div className={styles.customDropdown}>
                {typeFilters.map(value => (
                  <div
                    key={value}
                    className={classNames(styles.customOption, {
                      [styles.selected]: parseInt(filter.price) === value,
                    })}
                    data-name="aptType"
                    data-value={value}
                    onClick={handleOptionClick}
                    readOnly
                  >
                    {toCurrency(value)}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={classNames(styles.filter, {
              [styles.hidden]: !showFilters,
            })}
            // style={{ position: 'relative' }}
          >
            <label>MOVE-IN DATE</label>
            <input
              value={filter.date?.toLocaleDateString('en-US') || 'MM/DD/YYYY'}
              onClick={handleDateClick}
              readOnly
            />
            {showCalendar && (
              <Calendar
                className={styles.calendar}
                value={filter.date || new Date()}
                onChange={handleDateChange}
              />
            )}
          </div>
        </div>

        {renderUnits(page, pageCount, pageData, handleNext, handleBack, isTiny)}
        <div className={styles.priceDisclaimer}>
          * PRICE INCLUDES CONCESSIONS.
        </div>
        <br />
        {isTiny && (
          <div className={styles.footerPagination}>
            {page !== 0 ? (
              <div style={{ width: '12px' }}>
                <LeftArrow onClick={handleBack} />
              </div>
            ) : (
              <div style={{ width: '12px' }} />
            )}
            <div className={styles.position}>
              <span className={styles.page}>{page + 1}</span>
              <span className={styles.pageCount}>{pageCount}</span>
            </div>
            {page + 1 < pageCount ? (
              <div style={{ width: '12px' }}>
                <RightArrow onClick={handleNext} />
              </div>
            ) : (
              <div style={{ width: '12px' }} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default UnitFilter;
