import classNames from 'classnames';
import React, { useEffect } from 'react';
// import RellaxWrapper from "react-rellax-wrapper";
import { Link } from 'gatsby';
import favicon from "@images/envy/favicon.png";
import Seo from '@components/seo';

import Amenities from '@envy/Amenities';
import ContactForm from '@envy/ContactForm';
import Footer from '@envy/Footer';
import Layout from '@envy/Layout';
import News from '@envy/News';
import TheNeighborhood from '@envy/TheNeighborhood';
import UnitFilter from '@envy/UnitFilter';

import * as styles from './index.module.scss';

// const rellaxProps = {
//   xs: -10,
//   mobile: -10,
//   tablet: -10,
//   desktop: -3,
//   breakpoints: [500, 1064, 1377],
//   className: styles.noPointer,
// };

const IndexPage = () => {
  const handleScroll = () => {
    const elementList = Array.from(
      document.getElementsByClassName(styles.fadeInUp)
    );
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio > 0.4) {
          entry.target.classList.add(styles.isVisible);
        }
      });
    });

    elementList.forEach(el => observer.observe(el));
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout>
      <Seo
        title="Envy by Gomes | Newark, NJ"
        icon={favicon}
        description="Envy by Gomes, Newark New Jersey’s newest luxury apartment concept inspired by elegance and grandeur."
      />
      <div className={styles.container}>
        {/* START HERO */}
        <div className={classNames(styles.banner)}>
          <div className={classNames(styles.titleContainer, styles.fadeInUp)}>
            <span className={styles.companyName}>
              Envy by Gomes | Newark, New Jersey
            </span>
            <span className={classNames(styles.companyName, styles.mobile)}>
              Envy by Gomes
              <br />
              Newark, New Jersey
            </span>
            <span className={styles.title}>
              Life <br />
              of Envy
            </span>
            <span className={classNames(styles.quote)}>
              To feed on beauty, breathe <br />
              in bliss, and bask in luxury <br />– that is the well-lived life.
            </span>
          </div>
          <div className={styles.availability}>
            <Link to="#units">AVAILABILITY</Link>
          </div>
          <div className={styles.fadedText} />
          <div className={styles.sidelines} />
        </div>
        {/* END HERO */}
        {/* START ABOUT */}
        <div className={classNames(styles.relativeWrapper, styles.dark, styles.bottomPattern)}>
          <div className={styles.fadedPattern} />
          <div className={styles.foregroundContainer}>
            <div className={classNames(styles.fadeInUp)}>
              <div className={styles.topSections}>
                <h1 className={styles.topLineHeader}>Our Fantasy</h1>
                <p className={classNames(styles.lead)}>
                  When you come home to Envy you are wrapped in elegance and grandeur. Opulence is your doormat and extravagance your entryway. Here, you are the envy of the neighborhood.
                </p>

                <div className={styles.decor1}></div>

                <div className={styles.concept}>
                  <h3>The Concept</h3>
                  <p>At Envy you can feel the difference. Rich finishes, exclusive amenities, and sumptuous comfort give you a life of leisure in the lap of luxury. Our bespoke accommodations and embellished common areas are all designed for the pleasure of indulgent living. This is your elevated life. High above any standard, every aspect of dwelling here has been designed for a life worthy of envy.
                  </p>
                </div>
              </div>
              <div className={styles.quoteRow}>
                <div className={styles.elevatedLife}>
                  <h3>The Elevated Life</h3>
                  <p>In the morning, be invigorated by our superior gym and recreational rooms, then nurture your senses with a latte or green smoothie from the cafe next door. Settle into productivity in our brilliant co-working space that is expertly outfitted for all your technology needs and furnished in superior quality. In the afternoon, celebrate success with friends al fresco in our biergarten. And at the end of the day, with a glass of wine in your hand, relish the NYC views from our rooftop.
                  </p>
                </div>

                <div className={classNames(styles.cocoQuote, styles.fadeInUp)}>
                  <span className={styles.quote}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.921"
                      height="22.047"
                    >
                      <g data-name="quote-marks">
                        <path
                          fill="#eeac88"
                          d="M9.949 0l.921 1.03Q5.214 5.145 5.213 9.924q0 4.072 4.445 6.978l-5.163 5.146q-4.5-3.343-4.5-7.934Q0 7.608 9.949 0zm13.048 0l.919 1.03q-5.623 4.115-5.622 8.894 0 4.072 4.445 6.978l-5.18 5.146q-4.485-3.343-4.484-7.934Q13.081 7.608 23.002 0z"
                          data-name="Large Quote marks"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  <span className={classNames(styles.line, styles.first)}>
                    Luxury
                  </span>
                  <span className={classNames(styles.line, styles.second)}>
                    must be
                  </span>
                  <span className={classNames(styles.line, styles.third)}>
                    comfortable,
                  </span>
                  <span className={classNames(styles.line, styles.fourth)}>
                    otherwise it
                  </span>
                  <span className={classNames(styles.line, styles.fifth)}>
                    is not
                  </span>
                  <span className={classNames(styles.line, styles.sixth)}>
                    Luxury
                  </span>
                  <span className={classNames(styles.signature)}>
                    -COCO CHANEL
                  </span>
                </div>
              </div>
              <div className={styles.decor3Wrapper}>
                <div className={styles.decor3}></div>
              </div>
            </div>
          </div>
          <div className={styles.sidelines} />
        </div>
        {/* END ABOUT */}
        <div className={styles.fadedPatternBoxTopWrapper}><div className={styles.fadedPatternBoxTop} /></div>
        <div className={classNames(styles.relativeWrapper, styles.unitSection)}>
          <div className={styles.lifeWrapper}>
            <div>
              <div className={styles.decor4}></div>
            </div>
            <div>
              <h3>Life At Envy</h3>
              <h2>
                Magnificence <br />
                is Elemental
              </h2>
              <p>Our amenities are envious, but so are the rooms that raise Envy above the rest. Indulge in a sensual bath or sleep in tranquility in our expansive bedrooms. Our ennobled kitchens feature deep-veined quartz, modern, avant-garde appliances and distinguished cabinetry for dining in grandeur. Floor to ceiling windows will leave you soaring as you view the city lights over a star-lit evening. For our residents, we can assure that our state-of-the art security and custom storage solutions will give you the satisfaction of safekeeping.  A worry-free life is one of envy.</p>
              <p><i>&ldquo;You are not just buying into an apartment, you are buying a lifestyle&rdquo;<br />- Pedro Gomes</i></p>
            </div>
          </div>
          <div className={classNames(styles.sidelines, styles.stop)} />          
        </div>
        <div className={styles.unitFilterWrapper}>
          <UnitFilter />
          <Amenities />
          <div className={classNames(styles.relativeWrapper, styles.hardLeft)}>
            <h3>The Envy of Coworking</h3>
            <h2>
              Elevated Productivity
            </h2>
            <div className={styles.coworkCard}>
              <div className={styles.cardWrapper}>
                <div className={styles.cover} />
                <div className={styles.info}>
                  <p>
                    <span>At Envy we believe that inspiration comes from shared experiences, shared thoughts, and spaces that foster an atmosphere of creativity resulting in ultimate productivity.</span>
                    <a href="/property/envy/coworking">View Details</a>
                  </p>
                  <div className={styles.contentWrapper}></div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.patternContainer}></div>
          <TheNeighborhood />
          <div className={classNames(styles.fadedPatternBox)} />
          <div className={classNames(styles.lined)} />
        </div>
        <div className={classNames(styles.dark, styles.bottomPattern)}>
          <News />
          <ContactForm />
          <div className={styles.relativeWrapper} style={{ padding: 0 }}>
            <Footer style={{ marginTop: '104px' }} />
          </div>
          <div className={classNames(styles.sidelines)} />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
